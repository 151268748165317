/**
 * Webpack entry point
 *
 * This is the starting point for Webpack to bundle all assets
 *
 * @package Blueprint
 * @since 1.0
 * @version 1.0
 */

/* SCSS */
import './src/scss/app.scss';

/* JS */
import './src/js/app.js';

/* Inline images */
import logo from './src/img/logo.jpg';