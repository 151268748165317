/**
 * Navigation
 *
 * @package
 * @since 1.0
 * @version 1.0
 */

/**
 * jQuery
 *
 * @see {@link https://jquery.com/|jQuery}
 */
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

/**
 * Foundation
 *
 * @description Frontend framework
 * @see {@link https://www.npmjs.com/package/foundation-sites|Foundation}
 */
import Foundation from "foundation-sites";

/* Move location of nav in DOM by media query */
jQuery(function ($) {
  function navRelocate() {
    if (Foundation.MediaQuery.atLeast("large")) {
      $("#nav-main").appendTo("#nav-desktop");
    } else {
      $("#nav-main").appendTo("#nav-mobile");
    }
  }
  $(document).ready(function () {
    navRelocate();
  });

  $(window).on("changed.zf.mediaquery", function () {
    navRelocate();
  });
});
