/**
 * All Javascript for our project
 *
 * @package
 * @since 1.0
 * @version 1.0
 */

/**
 * jQuery
 *
 * @see {@link https://jquery.com/|jQuery}
 */
import jQuery from 'jquery';

window.$ = window.jQuery = jQuery;

/**
 * What input
 *
 * @description Foundation dependency used for accessibility
 * @see {@link https://www.npmjs.com/package/what-input|What input}
 */
import 'what-input';

/**
 * Foundation
 *
 * @description Frontend framework
 * @see {@link https://www.npmjs.com/package/foundation-sites|Foundation}
 */
import 'foundation-sites';

/**
 * Masonry
 *
 * @description Cascading grid layout library
 * @see {@link https://www.npmjs.com/package/masonry-layout}
 */
import Masonry from 'masonry-layout';

/* Init Foundation */
jQuery(function ($) {
	$(document).foundation();

	const grid = document.querySelector('.masonry-grid');
	if (grid) {
		const $grid = new Masonry(grid, {
			// options...
			itemSelector: '.grid-item',
			columnWidth: 300,
			gutter: 70,
		});
		$('.a11y-toggle-fontsize').on('click', function () {
			$grid.layout();
		});
	}
});

jQuery(function ($) {
	$('#accessibility').stop().animate({ 'margin-right': '-155px' }, 0);

	function toggleDivs() {
		const $inner = $('#accessibility');
		if ($inner.css('margin-right') === '-155px') {
			$inner.animate({ 'margin-right': '90px' }, 0);
			$('.accessibility-trigger').html('Accessibility');
		} else {
			$inner.animate({ 'margin-right': '-155px' }, 0);
			$('.accessibility-trigger').html('Accessibility');
		}
	}

	$('.accessibility-trigger').bind('click', function () {
		toggleDivs();
	});
});

jQuery(function ($) {
	$('.header__offcanvas-trigger button')
		.off()
		.on('click', function () {
			$('#masthead').toggleClass('expanded');
			$('#offCanvas').toggleClass('expanded');
			$('body').toggleClass('menu-expanded');
		});
	$('#offCanvas #menu-header > .menu-item').on('click', function (e) {
		if ($(this).find('.sub-menu').length > 0) {
			if (!$(this).hasClass('expanded')) {
				$('#offCanvas #menu-header > .menu-item').addClass(
					'not-expanded'
				);
				$(this).removeClass('not-expanded').addClass('expanded');
			} else if ($(e.target).siblings('.sub-menu').length && $(e.target).closest('ul').attr('id') === 'menu-header') {
				$('#offCanvas #menu-header > .menu-item')
					.removeClass('not-expanded')
					.removeClass('expanded');
			}
		}
	});
});

jQuery(function ($) {
	function checkElementLocation() {
		const $window = $(window);
		const bottomOfWindow = $window.scrollTop() + $window.height();
		$('.homepage-text-column').each(function (i, e) {
			checkVisible(e, bottomOfWindow);
		});
		$('.homepage-gallery img').each(function (i, e) {
			checkVisible(e, bottomOfWindow);
		});
	}

	function checkVisible(e, bottomOfWindow) {
		const $that = $(e);
		const topOfObject = $that.offset().top;
		//if element is in viewport, add the animate class
		if (bottomOfWindow > topOfObject) {
			$(e).addClass('fade-in');
		}
	}

	// if in viewport, show the animation
	checkElementLocation();

	$(window).on('scroll', function () {
		checkElementLocation();
	});
});
/* Nav */
import './nav';
